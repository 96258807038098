.dashboard-header {
  min-height: 125px !important;
  padding: 44px 0 !important;
}

.dashboard-header h3 {
  font-size: 1.8em;
  font-weight: 600;
}

.dashboard-credit__container {
  min-height: 60px;
  margin: 0 20px 50px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
}

.dashboard-credit-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  border: 1px solid #ee468d;
  border-radius: 30px;
  padding: 15px 5px 15px 20px;
  margin-right: 10px;
}

.dashboard-credit-img, .dashboard-credit-next-img {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ee468d;
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
}

.dashboard-credit-next-img:hover {
  background: white;
  cursor:pointer;
  border: 1px solid #ee468d;
  margin-right: 8px;
}

.dashboard-credit-next-img:hover img {
  content: url("/images/icons/arrow-right-pink.png");
}

.dashboard-credit-item span {
  font-size: 0.9em;
  margin-left: 5px;
}

.dashboard-credit-item hr {
  border-left: 2px solid #ee468d;
  height: 30px;
  width: auto;
  margin-left: 10px;
}

.dashboard-credit-right-side {
  margin-left: 50px;
}

.dashboard-service-row {
  justify-content: space-between;
  width: 97%;
  background-color: white;
  padding: 10px 20px 10px 20px;
  border-radius: 15px;
  border-bottom: 2px solid #ee468d;
  margin-bottom: 20px;
}

.dashboard-service-header {
  font-size: 1.5em;
  color: #ee468d;
  margin-top: 10px;
  margin-left: 25px;
}

.dashboard-service-title {
  font-size: 1.5em;
  color: #ee468d;
  font-weight: 500;
}

.dashboard-service-item {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
}

.dashboard-service-item-counter {
  margin-right: 10px;
  font-size: 1.1em;
  font-weight: 600;
  color: black;
}

.service-marker-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 70px;
}

.service-marker-container-grid {
  margin-left: 0px;
  margin-top: 10px;
  justify-content: space-around;
}

.job-marker {
  color: white;
  font-size: 0.9em;
  padding: 2px 10px 2px 10px;
  border-radius: 15px;
  width: fit-content;
  margin-top: 7px;
}

.job-marker.small {
  font-size: 0.8em;
}

.active-job-marker {
  background-color: #5cdb5c;
}

.draft-job-marker {
  background-color: #CDCDB4;
}

.review-job-marker {
  background-color: #ffa500;
}

.expired-job-marker {
  background-color: grey;
}

.common-marker {
  color: black;
  border: 1px solid #ee468d;
  margin-right: 5px;
}

.standard-row-format {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.right-corner {
  float: right;
  padding-right: 15px;
  padding-top: 15px;
}

.job-card-info-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.job-card-info-row span {
  color: black;
  font-weight: 600;
  margin-right: 10px;
}

.card.service-card  {
  min-height: 350px;
  position: relative;
  justify-content: space-around;
}

.card-button-bottom {
  width: 100%;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  padding-bottom: 25px;
}

.list-card-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

span.list-card-title {
    font-size: 1.4em;
    color: #ee468d;
}

a.list-card-title {
  text-decoration: none;
  font-weight: 500;
}

a.list-card-title:hover {
  text-decoration: underline;
}