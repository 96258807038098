/**
 * App Styles
 */
.ql-editor {
  font-size: 16px;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin-bottom: 32px;
}

.g-recaptcha {
  display: inline-block;
}

.card__title {
  margin-bottom: 32px;
}

.form__control[type="file"] {
  cursor: pointer;
  font-size: 12px;
  padding: 10px 0;
}

.bg--wave {
  background-image: url(/images/tegg_wave.png);
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 485px) {
  .card .grid > * {
    padding: 0;
  }
  .card .grid .grid__col-xs-12 {
    margin-bottom: 32px;
  }
}

.form__group--select .form__control {
  display: block;
  position: initial;
}

.ui.dropdown.selection {
  min-width: auto;
}

.ui.dropdown > .dropdown.icon {
  border: none;
}

.ui.dropdown > .dropdown.icon:before {
  content: "\e313";
}

.nav__container {
  width: 100% !important;
}

body#app-layout {
  padding-top: 0px;
}

/*# sourceMappingURL=app.css.map */
