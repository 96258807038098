.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-70 {
  margin-left: 70px;
}

.te-pink {
  color: #ee468d;
}

.row-center {
  text-align: center;
}