.description-wrapper {
  padding-top: 9px;
}

.description-box-b-container, .description-box-c-container {
  display: none;
  flex-direction: row;
  align-items: self-start;
}

.description-box-inner-container {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*align-items: self-start;*/
  position: relative;
  width: 100%;
}

.more-eblast-desc-container {
  margin-top: 20px;
  text-align: center;
}

.eblast-remove-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}

.eblast-remove-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.more-eblast-asset {
  margin-top: -30px;
}

.eblast-section-title {
  color: #ee468d;
  font-size: 1.1em;
  font-weight: 600;
  padding: 10px 0 0 20px;
}

.eblast-section-subtitle {
  font-size: 0.9em;
  color: black;
  padding: 10px 0 0 20px;
}

.eblast_notes {
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  resize: vertical;
}

.eblast-job-select {
  width: 100%;
  max-height: 200px;
  padding: 10px;
  border: 1px solid darkgrey;
}

.eblast-job-select option {
  padding-top: 5px;
}

.eblast-job-select::-webkit-scrollbar {
  width: 3px;
}

.eblast-job-select::-webkit-scrollbar-track {
  border: rgb(180, 180, 180);
  background-color: darkgrey;
}
.eblast-job-select::-webkit-scrollbar-thumb {
  background-color: #ee468d;
  border: 1px solid #ee468d;
}